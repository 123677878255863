body {
  color: #555;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: #50631a;
  font-family: EB Garamond, serif;
  font-size: 16px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-family: Oswald, sans-serif;
  font-weight: 300;
}

p {
  margin-bottom: 25px;
  line-height: 28px;
}

.centered {
  text-align: center;
}

a {
  color: #f85c37;
  word-wrap: break-word;
  -o-transition: color .1s ease-in, background .1s ease-in;
  transition: color .1s ease-in, background .1s ease-in;
}

a:hover, a:focus {
  color: #7b7b7b;
  outline: 0;
  text-decoration: none;
}

a:before, a:after {
  -o-transition: color .1s ease-in, background .1s ease-in;
  transition: color .1s ease-in, background .1s ease-in;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

#headerwrap {
  text-align: center;
  background: url("IMG_5300.7d7a4b2e.jpg") top no-repeat;
  background-attachment: relative;
  min-height: 700px;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  margin-top: -10px;
  padding-top: 20px;
}

#headerwrap h1 {
  color: #fff;
  margin-top: 150px;
  font-size: 70px;
  font-weight: 700;
}

#headerwrap h3 {
  color: #fff;
  font-family: EB Garamond, serif;
  font-size: 30px;
  font-style: oblique;
  font-weight: 100;
}

#headerwrap .logo {
  text-align: left;
  margin-top: 15px;
  margin-left: 20px;
}

#f {
  text-align: center;
  background: #50631a;
  padding-top: 70px;
  padding-bottom: 70px;
}

#f h3 {
  color: #fff;
  letter-spacing: 1px;
  font-size: 28px;
  font-weight: 700;
}

#f i {
  color: #fff;
  padding: 3px;
  font-size: 8px;
}

#f p {
  color: #f2f2f2;
  font-size: 20px;
}

#f img {
  text-align: center;
}

.menu {
  width: 260px;
  height: 100%;
  z-index: 10;
  text-align: left;
  position: fixed;
  top: 0;
  right: -200px;
}

.menu.menu-open {
  right: 0;
}

.menu-wrap {
  width: 200px;
  height: 100%;
  background: #1a1a1a;
  position: absolute;
  top: 0;
  left: 60px;
}

.menu h1.logo a {
  letter-spacing: .15em;
  text-transform: uppercase;
  color: #fff;
  margin-top: 20px;
  font-family: Oswald, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}

.menu h1.logo a:hover {
  color: #f85c37;
}

.menu img.logo {
  max-width: 160px;
  margin: 20px 0;
}

.menu a {
  color: gray;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

.menu a:hover, .menu a:active {
  color: #fff;
}

.menu a > i {
  float: left;
  vertical-align: middle;
  text-align: left;
  width: 25px;
  margin: 25px 2px;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
}

.menu-close {
  cursor: pointer;
  color: gray;
  width: 40px;
  height: 40px;
  -o-transition: all .1s ease-in-out;
  font-size: 14px;
  line-height: 40px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 20px;
  right: 5px;
}

.menu-close:hover {
  color: #fff;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.body-push {
  position: relative;
  left: 0;
  overflow-x: hidden;
}

.body-push-toright {
  left: 200px;
}

.body-push-toleft {
  left: -200px;
}

.menu, .body-push {
  -o-transition: all .3s ease;
  transition: all .3s;
}

#menuToggle {
  z-index: 11;
  text-align: center;
  color: #fff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  background: #00000040;
  font-size: 14px;
  line-height: 40px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
}

#menuToggle:hover {
  color: #fff;
  -o-transition: all .1s ease-in-out;
  background: #0003;
  transition: all .1s ease-in-out;
}

/*# sourceMappingURL=index.c7e2e749.css.map */
